import React, {PropsWithChildren} from 'react';

import classNames from 'classnames';

export interface ImageSectionProps {
  identifier?: string;
  title?: string;
  subheader?: string;
  description?: string;
  featureImage?: React.ReactNode;
  alignImage?: 'left' | 'right';
  className?: string;
  backgroundImage?: React.ReactNode;
}

const ImageSection: React.FC<PropsWithChildren<ImageSectionProps>> = props => {
  const {
    title,
    subheader,
    description,
    backgroundImage,
    featureImage,
    alignImage = 'left',
    className,
    identifier,
    children,
  } = props;
  const image = (
    <div
      className={classNames(
        'flex flex-col w-2/3 h-full md:w-full items-center',
      )}>
      {featureImage}
    </div>
  );

  return (
    <section
      id={identifier || undefined}
      className={classNames('relative flex flex-col', className)}>
      {backgroundImage && (
        <div className="absolute w-full h-full z-10 ">{backgroundImage}</div>
      )}
      <div className="relative container px-4 py-10 sm:px-6 lg:px-20 lg:py-14 z-20 mx-auto h-full grow flex flex-col items-center justify-center">
        <div className="grow content-center grid gap-6 md:grid-cols-2 items-center place-items-center md:gap-12 xl:gap-32">
          {alignImage === 'left' && image}

          <div className="mt-5 sm:mt-10 lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              <div className="space-y-2 md:space-y-4">
                {subheader && (
                  <p className="font-bold text-lg lg:text-xl text-primary">
                    {subheader}
                  </p>
                )}
                {title && (
                  <p className="font-bold text-4xl lg:text-5xl">{title}</p>
                )}
                {description && (
                  <p className="font-light text-2xl">{description}</p>
                )}
                {children}
              </div>
            </div>
          </div>

          {alignImage === 'right' && image}
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
