import {Language} from '../constants/language.constants';

export function isElementInViewport(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.left <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function getDefaultLanguage(): Language {
  const language = navigator.language.split('-').shift() ?? 'nl';
  const languageIndex = Object.values(Language).indexOf(language as Language);
  const key = Object.keys(Language)[languageIndex];
  return languageIndex > -1
    ? Language[key as keyof typeof Language]
    : Language.NL;
}

export function downloadFile(textInput: string, filename: string): void {
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:text/vcard;charset=utf-8, ' + encodeURIComponent(textInput),
  );
  link.setAttribute('download', filename);
  link.click();
}

export const toDataUrl = (
  src: string,
  outputFormat = 'image/jpeg',
): Promise<string> => {
  return new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx?.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL(outputFormat);
      resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''));
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        'data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      image.src = src;
    }
  });
};
