import React from 'react';

import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import * as Klaro from 'klaro';

import App from './App';
import './assets/fonts/Montserrat.woff2';
import './assets/fonts/NunitoSans.woff2';
import {klaroConfig} from './config/klaro';
import './global.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {InfoPage, PageType as InfoPageType} from './views/InfoPage/InfoPage';
import {LandingPage} from './views/LandingPage';
import {LegalDocumentPage} from './views/LegalDocumentPage/LegalDocumentPage';
import {ProfilePage} from './views/ProfilePage/ProfilePage';
import {ResetPassword} from './views/ResetPassword';
import {Unsubscribe} from './views/Unsubscribe';
import {LaunchPage} from './views/LaunchPage/LaunchPage';
import {PageType} from './download/constants';
import {DownloadPage} from './views/DownloadPage/DownloadPage';
import {InvitePage} from './views/InvitePage/InvitePage';

declare global {
  interface Window {
    klaro: unknown;
    klaroConfig: unknown;
  }
}

window.klaro = Klaro;
window.klaroConfig = klaroConfig;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/reset-password/*" element={<ResetPassword />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/users/:id" element={<ProfilePage />} />
          <Route
            path="/privacy"
            element={<InfoPage type={InfoPageType.PRIVACY} />}
          />

          <Route
            path="/terms"
            element={<InfoPage type={InfoPageType.TERMS} />}
          />
          {/* TODO Replace with a updated FAQ */}
          <Route
            path="/alpha/faq"
            element={<InfoPage type={InfoPageType.FAQ_ALPHA} />}
          />
          <Route
            path="/legal-documents/:id/versions/:versionId"
            element={<LegalDocumentPage />}
          />
          <Route path="/legal-documents/:id" element={<LegalDocumentPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/launch" element={<LaunchPage />} />
          <Route
            path="/download/prelaunch"
            element={<DownloadPage type={PageType.DOWNLOAD_PRELAUNCH} />}
          />
          <Route
            path="/download/"
            element={<DownloadPage type={PageType.DOWNLOAD_MAIN} />}
          />
          <Route path="/join" element={<InvitePage />} />
        </Routes>
      </App>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
