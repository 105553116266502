import React, {useState} from 'react';

import {Language, supportedLang} from '../constants/language.constants';
import useQuery from './useQuery';
import {getDefaultLanguage} from '../utils';
import {normalizeLanguageTag} from '../utils/i18n';

// A custom hook that determines the language to be used
export default function useLanguage(
  fallback?: Language,
): [Language, React.Dispatch<React.SetStateAction<Language>>] {
  const query = useQuery();

  const queryLang = query.get('lang');
  const normalizedTag = normalizeLanguageTag(queryLang as string);
  const [lang, setLang] = useState(
    supportedLang.indexOf(normalizedTag as Language) !== -1 &&
      queryLang !== null
      ? (normalizedTag as Language)
      : fallback ?? getDefaultLanguage(),
  );

  return React.useMemo(() => [lang, setLang], [lang]);
}
