import React from 'react';

import {Link as RouterLink} from 'react-router-dom';

import Link from '../link/Link';
import './footer.scss';

function Footer(): JSX.Element {
  return (
    <footer className="Footer-footer">
      <div className="Footer-color-container" />
      <div className="Footer-text-container">
        <div className="Footer-text-part">
          <Link to="mailto:support@slip-app.nl" className="no-wrap m-0">
            support@slip-app.nl
          </Link>
          <p className="no-wrap m-0">KvK-nummer: 77722124</p>
          <p className="m-0">
            Copyright © {new Date().getFullYear()} All rights reserved -
            Designed by Slip B.V.
          </p>
        </div>
        <div className="Footer-text-part">
          <nav className="Footer-nav">
            <ul>
              <li>
                <RouterLink to="/" className={'link'}>
                  Home
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/legal-documents/privacy-policy"
                  className={'link'}>
                  Privacy
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/legal-documents/terms-and-conditions"
                  className={'link'}>
                  Terms
                </RouterLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
