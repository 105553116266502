import React, {useEffect} from 'react';

import {Link, useParams} from 'react-router-dom';

import VCard from 'vcard-creator';

import logo from '../../assets/images/logo-white.svg';
import Button from '../../components/button/Button';
import Circle from '../../components/circle/Circle';
import Icon from '../../components/icon';
import {downloadFile, toDataUrl} from '../../utils';
import './ProfilePage.scss';
import users from './users.json';

interface BusinessCardUserType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  bio: string;
  company: string;
  website: string;
  avatar?: string;
  role?: string;
}

export function ProfilePage(): JSX.Element {
  const params = useParams<{id: string}>();
  const paramId = Number(params.id);
  const user = (users as Array<BusinessCardUserType>).find(
    user => user.id === paramId,
  );

  useEffect(() => {
    const footer = document.querySelector('footer');
    footer?.classList.add('hidden');
    const logo = document.querySelector('.App-logo');
    logo?.classList.add('hidden');

    return () => {
      footer?.classList.remove('hidden');
      logo?.classList.remove('hidden');
    };
  }, []);

  if (!user) {
    // TODO #31 Redirect to 404
    return (
      <>
        <h1>Oops, could not find user.</h1>
      </>
    );
  }
  return (
    <div className="ProfilePage-container">
      <Link className="ProfilePage-logo" to="/">
        <img src={logo} alt="slip-logo-white" />
      </Link>
      <div className="ProfilePage-container-profile">
        <Circle
          colors={['#FFC148', '#FF5975']}
          size={'300vw'}
          bottom={'0'}
          deg={120}
        />
        <div className="ProfilePage-container-top">
          <div className="ProfilePage-avatar-container">
            <img
              className="ProfilePage-avatar"
              src={user.avatar}
              alt={`Profile picture of ${user.firstName} ${user.lastName}`}
            />
            {user.role ? (
              <div className="ProfilePage-avatar-badge">
                <span className="ProfilePage-avatar-badge-text">
                  {user.role}
                </span>
              </div>
            ) : null}
          </div>
          <div>
            <h2>{`${user.firstName} ${user.lastName}`}</h2>
            <h3>{`${user.bio}`}</h3>
          </div>
        </div>
      </div>
      <div className="ProfilePage-info">
        <div
          className="info-row"
          onClick={() => {
            window.open(`mailto:${user.email}`, '_blank');
          }}>
          <Icon name="mail-outline" />
          <p>{user.email}</p>
        </div>
        <div
          className="info-row"
          onClick={() => {
            window.open(`tel:${user.phone}`, '_blank');
          }}>
          <Icon name="call-outline" />
          <p>{user.phone}</p>
        </div>
        <div
          className="info-row"
          onClick={() => {
            window.open(`${user.website}`, '_blank');
          }}>
          <Icon name="globe-outline" />
          <p>{user.website}</p>
        </div>
      </div>
      <Button
        onClick={async () => {
          const vCard = new VCard();
          vCard
            .addName(user.lastName, user.firstName)
            .addCompany(user.company)
            .addEmail(user.email, 'WORK')
            .addJobtitle(user.bio)
            .addPhoneNumber(user.phone);
          if (user.avatar) {
            const image = await toDataUrl(user.avatar);
            vCard.addPhoto(image, 'JPEG');
          }
          downloadFile(
            vCard.toString(),
            `${user.firstName} ${user.lastName}.vcf`,
          );
        }}>
        Save Contact
      </Button>
    </div>
  );
}
