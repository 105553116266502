import React, {useEffect, useState} from 'react';

import {Platform, platformBadges} from '../../constants/app.constants';
import {Language} from '../../constants/language.constants';
import {downloadPageData, PageType} from '../../download/constants';
import './DownloadBadges.scss';

export function DownloadBadges({
  type,
  language,
  platforms,
}: {
  type: PageType;
  language: Language;
  platforms?: Platform[];
}): JSX.Element {
  const [data, setData] = useState(downloadPageData[type]);

  useEffect(() => {
    setData(downloadPageData[type]);
  }, [type]);

  return (
    <div className="Badges">
      {Object.entries(data.platforms).map((x, i) => {
        if (x[1] === undefined) {
          return null;
        }
        const platform = x[0] as Platform;

        if (platforms && !platforms.includes(platform)) {
          return null;
        }

        return (
          <a key={i} href={x[1]?.url}>
            <img
              className={platform}
              src={platformBadges[platform].icon}
              alt={platformBadges[platform].iconAlt[language]}
            />
          </a>
        );
      })}
    </div>
  );
}
