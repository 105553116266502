import React from 'react';

import {Fade} from 'react-awesome-reveal';

import iphoneFrameHome from '../assets/images/iPhoneFrame1.w375.png';
import iphoneFrameProfile from '../assets/images/iPhoneFrame2.w375.png';
import talentDiscovery from '../assets/images/talent-discovery.png';
import officeFun from '../assets/videos/officefun.webm';
import videoPainting from '../assets/videos/painting.webm';
import Circle from '../components/circle/Circle';
import Icon from '../components/icon';
import ImageSection from '../components/imageSection/ImageSection';
import Phone from '../components/phone/Phone';
import PhoneVideo from '../components/phonevideo/PhoneVideo';
import {Language} from '../constants/language.constants';
import {PageType} from '../download/constants';
import {DownloadBadges} from '../components/download/DownloadBadges';

export function LandingPage(): JSX.Element {
  return (
    <>
      {/* Hero */}
      <ImageSection
        className="mt-20 md:mt-0"
        alignImage="right"
        featureImage={
          <Fade direction="right" delay={150} triggerOnce>
            <PhoneVideo src={videoPainting} frame="jenney" />
          </Fade>
        }
        backgroundImage={
          <>
            <Circle
              colors={['#FFC148', '#FF5975']}
              size={'70vw'}
              right={'-40vw'}
              top={'-40vw'}
            />
            <Circle
              colors={['#FF5975', '#5A68FF']}
              size={'50vw'}
              left={'-20vw'}
              top={'70vh'}
            />
          </>
        }>
        <Fade direction="down" triggerOnce>
          <h1 className="App-section-h3 m-0">Hello. Meet Slip.</h1>
        </Fade>
        <Fade direction="up" triggerOnce>
          <p className="App-section-h2 m-0 leading-none">
            Slip makes professional networking more personal and meaningful.
          </p>
        </Fade>
      </ImageSection>

      {/* Celebrate together */}
      <ImageSection
        className="h-screen"
        featureImage={
          <Fade direction="up" triggerOnce>
            <Phone src={iphoneFrameHome} />
          </Fade>
        }>
        <Fade direction="down" triggerOnce>
          <h2 className="App-section-h4 m-0">Show and Inspire</h2>
        </Fade>
        <Fade direction="up" triggerOnce>
          <p className="App-section-h2 m-0 leading-none">
            Have fun showing your skills, get inspired by others, and get job
            offers effortlessly.
          </p>
        </Fade>
      </ImageSection>

      {/* Join Community */}
      <ImageSection
        className="h-screen my-6 sm:my-0"
        alignImage="right"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div>
              <PhoneVideo src={officeFun} frame="manouk" />
            </div>
          </Fade>
        }
        backgroundImage={
          <>
            <Circle
              colors={['#FFCC51', '#FF8E65']}
              size={'75vw'}
              left={'50vw'}
            />
            <Circle
              colors={['#FFCC51', '#FFFF40']}
              size={'50vw'}
              left={'-20vw'}
              className="bottom-[-20vh] md:bottom-[-50vh]"
            />
          </>
        }>
        <h2 className="App-section-h4 m-0">Join the community</h2>
        <p className="App-section-h2 m-0 leading-none">
          Be part of an emerging community of young professionals.
        </p>
      </ImageSection>

      {/* Share and Connect */}
      <section className="p-12 my-6 sm:my-0 relative App-section-container-bg flex flex-col items-center justify-center h-[60vh] md:h-screen z-30">
        <div className="">
          <Fade direction="down" triggerOnce>
            <h2 className="App-section-h4 text-white m-0">Build and connect</h2>
          </Fade>
          <Fade direction="up" triggerOnce>
            <p className="App-section-h2 text-white m-0 leading-none">
              Build a professional network years before graduation.
            </p>
          </Fade>
        </div>
      </section>

      {/* Challenge the world */}
      <ImageSection
        className="h-screen my-8 sm:my-0"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div>
              <Phone src={iphoneFrameProfile} />
            </div>
          </Fade>
        }
        backgroundImage={
          <Circle
            colors={['#FFFF40', '#FFCC51']}
            size={'40vw'}
            left={'-10vw'}
            top={'40vh'}
          />
        }>
        <div className="App-challenge max-w-xs md:max-w-xl">
          <h4 className="App-section-h4 text-white m-0">Be proud</h4>
          <h2 className="App-section-h2 text-white m-0">
            Raise your profile with companies by sharing your proudest moments
            with the world.
          </h2>
        </div>
      </ImageSection>

      {/* Forbusiness */}
      <ImageSection
        className="h-screen mt-16 sm:mt-0"
        alignImage="right"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div className="flex flex-col items-center justify-center">
              <img src={talentDiscovery} alt="phone" />
            </div>
          </Fade>
        }>
        <h4 className="App-section-h4 m-0">Discover new Talent</h4>
        <h2 className="App-section-h2 m-0">
          Browsing through our platform, you'll discover a sea of potential
          candidates showcasing their skills and achievements in creative ways.
          Find out more business features.
        </h2>
        <div className="App-section-button flex flex-row items-center text-3xl md:text-4xl text-primary font-bold">
          <a href="https://forbusiness.slip-app.nl">Business</a>
          <Icon name="arrow-forward-outline" size={'large'} />
        </div>
      </ImageSection>

      {/* Download Section */}
      <div className="flex flex-col items-center justify-center mb-44">
        <h4 className="App-section-h2 font-semibold">
          Download the app and start showing off your skills 🤩
        </h4>
        <DownloadBadges
          type={PageType.DOWNLOAD_MAIN}
          language={Language.EN}
          platforms={['iOS', 'Android']}
        />
      </div>
    </>
  );
}
