import {DownloadPageData} from '../types';
import {default as escape} from 'escape-html';

const renderAvatarWithName = ({
  firstName,
  avatarUri,
  fallbackText = 'us',
}: InviteParameters & {fallbackText: string}) =>
  firstName
    ? `${
        avatarUri ? `![avatar](${avatarUri})` : ''
      }<span class="username">${escape(firstName)}</span>`
    : fallbackText;

export type InviteParameters = {firstName?: string; avatarUri?: string};

export const downloadPageData_Invite: DownloadPageData<InviteParameters> = {
  text: {
    en: ({firstName, avatarUri}: InviteParameters) =>
      `# Welcome to ![titleLogo](/logo.svg)\n ## Join ${renderAvatarWithName({
        firstName,
        avatarUri,
        fallbackText: 'us',
      })} and start showing off your skills on Slip 🤩!${
        firstName
          ? `  \nTap this text after installing to find ${firstName}.`
          : ''
      }`,
    nl: ({firstName, avatarUri}: InviteParameters) =>
      `# Welkom bij ![titleLogo](/logo.svg)\n ## Join ${renderAvatarWithName({
        firstName,
        avatarUri,
        fallbackText: 'ons',
      })} en kom je skills tonen op Slip 🤩!${
        firstName
          ? `  \nKlik hier na het installeren om ${firstName} te vinden.`
          : ''
      }`,
  },
  platforms: {
    iOS: {
      url: 'https://apps.apple.com/us/app/slip-app/id6449608428',
    },
    Android: {
      url: 'https://play.google.com/store/apps/details?id=com.slipbv.slipapp&utm_source=website&utm_campaign=invite-2024&pcampaignid=invite-2024-webpage',
    },
  },
};
