import React from 'react';

import classNames from 'classnames';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
}

function Card({className = '', children}: CardProps): JSX.Element {
  return (
    <div
      className={classNames(
        'relative container bg-gray-100 shadow-md rounded-lg p-4 md:p-16 mx-auto md:mt-10 mb-20',
        className,
      )}>
      {children}
    </div>
  );
}

export default Card;
