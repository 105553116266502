export const klaroConfig = {
  styling: {
    theme: ['dark', 'bottom', 'wide'],
  },
  acceptAll: true,
  mustConsent: true,
  default: true,
  lang: 'en',
  services: [
    {
      name: 'googleAnalytics',
      purposes: ['analytics'],
    },
  ],
  privacyPolicy: '/privacy',
  translations: {
    en: {
      consentNotice: {
        description:
          "Welcome to the Slip website! In order to provide the best experience, we'd like to use some services that need cookies. Please let us know whether that's OK, or alternatively, specify which services you'll accept.",
      },
      consentModal: {
        description:
          "We'd like to use various services in order to provide the best website experience and to understand how our website is being used. Let us know which services you'll let us use.",
      },
      googleAnalytics: {
        title: 'Google Analytics',
        description:
          'We use Google Analytics to understand how our visitors are using our website.',
      },
      purposes: {
        analytics: 'Analytics',
      },
    },
  },
};
