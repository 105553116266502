import React from 'react';

function Circle({
  colors,
  size,
  left,
  right,
  top,
  bottom,
  className,
  deg = 145,
}: {
  colors: string[];
  left?: number | string;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
  size: number | string;
  deg?: number;
  className?: string;
}): JSX.Element {
  if (colors.length === 0) {
    throw new Error('Please add at least 1 color to the array');
  }

  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        left,
        right,
        top,
        bottom,
        width: size,
        height: size,
        background:
          colors.length === 1
            ? colors[0]
            : `linear-gradient(${deg}deg, ${colors[0]} 0%, ${colors[1]} 100%) 0% 0% no-repeat padding-box`,
        borderRadius: size,
        zIndex: -5,
      }}
    />
  );
}

export default Circle;
