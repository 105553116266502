import React from 'react';

import styled from '@emotion/styled';

const Error = styled('div')({
  fontWeight: 900,
  color: '#ff0000',
  width: '100%',
  textAlign: 'center',
});

type APIError = {
  response?: {
    data?: {
      error?: {
        message: string;
        details?: {
          path: string;
          message: string;
        }[];
      };
    };
  };
};

export function ErrorMessage(props: {
  children?: JSX.Element | JSX.Element[];
  error?: Error | APIError;
}): JSX.Element {
  const error = props.error || props.children || null;
  let errorData: string | string[] | Record<string, unknown> | null = null;
  let errorToRender: string | string[] | JSX.Element[] = '';

  // error may be an Error, an array of strings, or an object
  if (error) {
    if ('response' in error) {
      if (error?.response?.data?.error?.details) {
        if (Array.isArray(error.response.data.error.details)) {
          errorData = error.response.data.error.details.map(
            x => x.path.substring(1) + ': ' + x.message,
          );
        } else {
          errorData = JSON.stringify(error.response.data.error.details);
        }
      } else if (error?.response?.data?.error?.message) {
        errorData = error.response.data.error.message;
      }
    } else {
      errorData = error.toString();
    }

    if (Array.isArray(errorData)) {
      errorToRender = errorData.map((x, i) => <p key={i}>{x}</p>);
    } else if (
      errorData &&
      typeof errorData === 'object' &&
      Object.keys(errorData).length
    ) {
      errorToRender = 'Error: ' + JSON.stringify(errorData);
    } else if (typeof errorData === 'string') {
      errorToRender = errorData;
    }
  }

  return <Error>{errorToRender}</Error>;
}
