const themeLight = {
  dark: false,
  colors: {
    primary: '#3ACCE1',
    secondary: '#665EFF',
    background: '#F5F5F6',
    card: '#FFF',
    text: '#454F63',
    border: 'transparent',
    notification: '#3ACCE1',
    primary0: '#665EFF',
    primary1: '#5773FF',
    primary2: '#3497FD',
    primary3: '#3ACCE1',
    contrast0: '#C840E9',
    contrast1: '#FF4F9A',
    contrast2: '#FF9057',
    contrast3: '#FFB900',
    white: '#FFF',
    black: '#000',
    grey0: '#F7F7FA',
    grey1: '#F4F4F6',
    grey2: '#959DAD',
    grey3: '#78849E',
    grey4: '#454F63',
    grey5: '#2A2E43',
    greyOutline: '#F7F7FA',
    searchBg: '#FFF',
    success: '#3ACCE1',
    error: '#FF4F9A',
    warning: '#FF9057',
    divider: '#F4F4F6',
  },
};

export {themeLight};
