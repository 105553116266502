export type Environment = 'local' | 'staging' | 'production';

export const urlEnvironments: {[url: string]: Environment} = {
  'staging.slip-app.nl': 'staging',
  localhost: 'local',
  'slip-app.nl': 'production',
  'www.slip-app.nl': 'production',
};

export const URLs: {
  [E in Environment]: {
    loopback: string;
    supabase: {url: string; anonKey: string};
  };
} = {
  production: {
    loopback: 'https://api.slip-app.nl',
    supabase: {
      url: 'https://supabase.prod02.slip-app.nl',
      anonKey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlIiwiaWF0IjoxNjk0NDU4MDE4LCJleHAiOjE4MzY1MTQ4MDB9.5yUjyX6qRJfkMzWUY_LQIIvn7M5Gyqb6Cb7MyQVAIY0',
    },
  },
  staging: {
    loopback: 'https://api-staging.slip-app.nl',
    supabase: {
      url: 'https://supabase.dev01.slip-app.nl',
      anonKey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgInJvbGUiOiAiYW5vbiIsCiAgICAiaXNzIjogInN1cGFiYXNlIiwKICAgICJpYXQiOiAxNjc4NjYyMDAwLAogICAgImV4cCI6IDE4MzY1MTQ4MDAKfQ.AqLUl0wMU4FoP94BtlRQaIt1THnb2VCSzbN8vuY8ORQ',
    },
  },
  local: {
    loopback: 'http://localhost:8080',
    supabase: {
      url: 'http://localhost:8000',
      anonKey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE',
    },
  },
};
