import React from 'react';
import {DetailedHTMLProps, HTMLAttributes, useState} from 'react';

import './Icon.scss';

export interface IconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string;
  size?: 'small' | 'large';
  type?: 'outline' | 'sharp';
  color?: string;
  hoverColor?: string;
}

function Icon({
  name,
  size,
  type,
  color,
  hoverColor,
  ...rest
}: IconProps): JSX.Element {
  const [colorState, setColor] = useState(color);
  const clickable = rest.onClick !== undefined;

  const onMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    rest.onMouseEnter?.(event);
    if (hoverColor != undefined) {
      setColor(hoverColor);
    }
  };
  const onMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    rest.onMouseLeave?.(event);
    setColor(color);
  };

  return (
    <div
      className={'Icon transition'}
      {...rest}
      style={{cursor: clickable ? 'pointer' : 'default', color: colorState}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <ion-icon
        name={name}
        size={size}
        type={type !== undefined ? `-${type}` : ''}
      />
    </div>
  );
}

export default Icon;
