import {DownloadPageData} from '../types';
import {downloadPageData_Alpha} from './alpha';
import {downloadPageData_Beta} from './beta';
import {downloadPageData_Launch} from './launch';
import {downloadPageData_Main} from './main';
import {downloadPageData_Invite, InviteParameters} from './invite';
import {downloadPageData_PreLaunch} from './prelaunch';

export enum PageType {
  DOWNLOAD_ALPHA = 'download_alpha',
  DOWNLOAD_BETA = 'download_beta',
  DOWNLOAD_PRELAUNCH = 'download_prelaunch',
  LAUNCH = 'launch',
  DOWNLOAD_MAIN = 'main',
  DOWNLOAD_INVITE = 'invite',
}

export const downloadPageData: {
  [key in PageType]: DownloadPageData | DownloadPageData<InviteParameters>;
} = {
  [PageType.DOWNLOAD_ALPHA]: downloadPageData_Alpha,
  [PageType.DOWNLOAD_BETA]: downloadPageData_Beta,
  [PageType.DOWNLOAD_PRELAUNCH]: downloadPageData_PreLaunch,
  [PageType.LAUNCH]: downloadPageData_Launch,
  [PageType.DOWNLOAD_MAIN]: downloadPageData_Main,
  [PageType.DOWNLOAD_INVITE]: downloadPageData_Invite,
};
