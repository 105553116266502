import React, {useEffect, useState} from 'react';

import ReactMarkdown from 'react-markdown';

import Card from '../../components/card/Card';
import LangSelection from '../../components/langSelection/LangSelection';
import {Language, supportedLang} from '../../constants/language.constants';
import FAQ from '../../info/faq.json';
import FAQ_ALPHA from '../../info/faq_alpha.json';
import Privacy from '../../info/privacy-policy.json';
import Terms from '../../info/terms.json';
import './InfoPage.scss';

export enum PageType {
  PRIVACY = 'privacy',
  TERMS = 'terms',
  FAQ = 'faq',
  FAQ_ALPHA = 'faq_alpha',
}

type InfoPageMap = {
  privacy: typeof Privacy;
  terms: typeof Terms;
  faq: typeof FAQ;
  faq_alpha: typeof FAQ_ALPHA;
};

interface Props {
  type: PageType;
  lang?: Language;
}

const infoMap: InfoPageMap = {
  privacy: Privacy,
  terms: Terms,
  faq: FAQ,
  faq_alpha: FAQ_ALPHA,
};

export function InfoPage(props: Props) {
  const [lang, setLang] = useState(props.lang ?? Language.NL);
  const [text, setText] = useState(infoMap[props.type][lang]);

  useEffect(() => {
    setText(infoMap[props.type][lang]);
  }, [lang, props.type]);

  return (
    <section className="App-section-container Info-Page">
      <Card className={'Info-Page-Card'}>
        <LangSelection
          languages={supportedLang}
          selected={lang}
          onSelect={selected => {
            const isInSupportedLang =
              supportedLang.indexOf(selected as Language) !== -1;
            if (isInSupportedLang) {
              setLang(selected as Language);
            }
          }}
        />
        <ReactMarkdown>{text}</ReactMarkdown>
      </Card>
    </section>
  );
}
