import {DownloadPageData} from '../types';

export const downloadPageData_Main: DownloadPageData = {
  text: {
    en: '# Welcome to ![titleLogo](/logo.svg)\n ## Download the app and start showing off your skills 🤩',
    nl: '# Welkom bij ![titleLogo](/logo.svg)\n ## Download de app en toon je skills 🤩',
  },
  platforms: {
    iOS: {
      url: 'https://apps.apple.com/us/app/slip-app/id6449608428',
    },
    Android: {
      url: 'https://play.google.com/store/apps/details?id=com.slipbv.slipapp&utm_source=website&utm_campaign=launch-2023&pcampaignid=download-2023-webpage',
    },
  },
};
