import React, {useCallback} from 'react';

import './langSelection.scss';

interface LangProps {
  selected: string;
  languages: string[];
  onSelect: (lang: string) => void;
}

function LangSelection(props: LangProps): JSX.Element {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      const target = event.currentTarget;
      props.onSelect(target.textContent?.toLowerCase() || '');
    },
    [props],
  );

  return (
    <div className={'LangSelection-container'}>
      {props.languages.map((value, index) => {
        return (
          <p key={index}>
            {index > 0 ? <span>&nbsp;|&nbsp;</span> : null}
            <span
              onClick={onClick}
              className={
                'lang-button ' + (props.selected === value ? 'selected' : '')
              }>
              {value.toUpperCase()}
            </span>
          </p>
        );
      })}
    </div>
  );
}

export default LangSelection;
