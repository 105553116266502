import React, {useState} from 'react';

import {DownloadCard} from '../../components/download/DownloadCard';
import LangSelection from '../../components/langSelection/LangSelection';
import {Language, supportedLang} from '../../constants/language.constants';
import {getDefaultLanguage} from '../../utils';
import './DownloadPage.scss';
import {PageType} from '../../download/constants';
import {InviteParameters} from '../../download/invite';

interface DownloadPageProps {
  type: PageType;
  language?: Language;
  narrative?: 'default' | 'invite';
  parameters?: InviteParameters | null;
}

export function DownloadPage({
  language,
  type,
  parameters,
}: DownloadPageProps): JSX.Element {
  const [lang, setLang] = useState(language ?? getDefaultLanguage());

  return (
    <section className="App-section-container Download-Page">
      <LangSelection
        languages={supportedLang}
        selected={lang}
        onSelect={selected => {
          supportedLang.includes(selected as Language) &&
            setLang(selected as Language);
        }}
      />
      <DownloadCard language={lang} type={type} parameters={parameters} />
    </section>
  );
}
