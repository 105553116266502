import React, {useEffect, useState} from 'react';

import ReactMarkdown from 'react-markdown';

import Card from '../../components/card/Card';
import {Language} from '../../constants/language.constants';
import {downloadPageData, PageType} from '../../download/constants';
import {DownloadBadges} from './DownloadBadges';
import './DownloadCard.scss';
import {Platform} from '../../constants/app.constants';
import {InviteParameters} from '../../download/invite';
import rehypeRaw from 'rehype-raw';

export function DownloadCard({
  type,
  language,
  platforms,
  parameters,
}: {
  type: PageType;
  language: Language;
  platforms?: Platform[];
  parameters?: InviteParameters | null;
}): JSX.Element {
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    const data = downloadPageData[type];
    const transformer = data.text[language];
    if (typeof transformer === 'string') {
      setContent(transformer);
      return;
    }
    if (typeof transformer !== 'function') {
      console.error(
        'Failed to find transformer for download page',
        type,
        'language',
        language,
      );
      return;
    }
    setContent(transformer(parameters ?? {}));
  }, [language, parameters, type]);

  return (
    <Card className={'Download-Page-Card'}>
      <ReactMarkdown rehypePlugins={[rehypeRaw] as never}>
        {content}
      </ReactMarkdown>

      <DownloadBadges type={type} language={language} platforms={platforms} />
    </Card>
  );
}
