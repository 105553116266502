import {useCallback, useEffect, useRef} from 'react';
import {DownloadCard} from '../../components/download/DownloadCard';
import LangSelection from '../../components/langSelection/LangSelection';
import {Language, supportedLang} from '../../constants/language.constants';
import useLanguage from '../../hooks/useLanguage';
import './LaunchPage.scss';
import {PageType} from '../../download/constants';
import {isAndroid, isIOS} from 'react-device-detect';
import {Platform, PlatformMap} from '../../constants/app.constants';
import useQuery from '../../hooks/useQuery';
import {InviteParameters} from '../../download/invite';

type Screen = 'magiclinkLogIn' | 'password' | 'profile';
const screens: {[S in Screen]: true} = {
  magiclinkLogIn: true,
  password: true,
  profile: true,
};

export function LaunchPage({
  language,
  parameters,
  type,
  goto: routingGoto,
}: {
  language?: Language;
  parameters?: InviteParameters | null;
  type?: PageType;
  goto?: Screen;
}): JSX.Element {
  const [lang, setLang] = useLanguage(language);
  const ref = useRef<HTMLAnchorElement>(null);
  const query = useQuery();

  const onClick = useCallback(() => {
    const totp = query.get('totp');
    const goto =
      routingGoto ?? query.get('goto') ?? (totp ? 'magiclinkLogIn' : undefined); // default to magiclinkLogIn if totp is present for backward compatibility
    if (!goto || goto in screens) {
      let href = 'slipapp://';
      switch (goto) {
        case 'magiclinkLogIn':
          href = `slipapp://goto/${goto}/${totp ? `?totp=${totp}` : ''}`;
          break;
        case 'profile':
          href = `slipapp://goto/${goto}/?id=${query.get('inviter')}`;
          break;
      }
      window.location.href = href;

      // Close window if we have lost focus (i.e. app has opened) within 100 ms
      setTimeout(() => {
        if (document.hasFocus()) {
          return;
        }
        window.close();
      }, 200);
    }
  }, [query, routingGoto]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.click();
      }
    }, 10);
  });

  const platforms: Platform[] | undefined = isAndroid
    ? [PlatformMap.Android]
    : isIOS
    ? [PlatformMap.iOS]
    : undefined;

  return (
    <section className="App-section-container Download-Page">
      <LangSelection
        languages={supportedLang}
        selected={lang}
        onSelect={selected => {
          supportedLang.includes(selected as Language) &&
            setLang(selected as Language);
        }}
      />
      <a ref={ref} id="launch" target="_blank" onClick={onClick}>
        <DownloadCard
          language={lang}
          type={type ?? PageType.LAUNCH}
          platforms={platforms}
          parameters={parameters}
        />
      </a>
    </section>
  );
}
