import React, {useCallback} from 'react';

import './link.scss';

interface LinkProps {
  className?: string;
  to: string;
  target?: '_blank' | '_self';
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

// A link disguised in a <p> element 🥷
// Use this link for e.g. mailto parts
function Link({
  className = '',
  children,
  to,
  onClick,
  target = '_self',
}: LinkProps): JSX.Element {
  const _onClick = useCallback(
    (e: React.MouseEvent) => {
      window.open(to, target);
      onClick?.(e);
    },
    [to, onClick, target],
  );
  return (
    <p className={'Link ' + className} onClick={_onClick}>
      {children}
    </p>
  );
}

export default Link;
