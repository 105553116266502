import iconAppleAppStore from '../assets/images/apple-app-store-badge.svg';
import iconGooglePlayStore from '../assets/images/google-play-badge.png';
import {Language} from './language.constants';

export type Platform = 'iOS' | 'Android';
export const PlatformMap: {[key in Platform]: Platform} = {
  iOS: 'iOS',
  Android: 'Android',
};

export const platformBadges: {
  [P in Platform]: {icon: string; iconAlt: {[L in Language]: string}};
} = {
  iOS: {
    icon: iconAppleAppStore,
    iconAlt: {
      nl: 'Download Slip voor iOS',
      en: 'Download Slip for iOS',
    },
  },
  Android: {
    icon: iconGooglePlayStore,
    iconAlt: {
      nl: 'Download Slip voor Android',
      en: 'Download Slip for Android',
    },
  },
};
