import React from 'react';

import {CircularProgress, Theme} from '@mui/material';

import styled from '@emotion/styled';

const ThrobberContainer = styled('div')({
  margin: 'auto',
  width: '40px',
});

interface ThrobberStyledProps {
  small?: boolean;
}

const ThrobberStyled = styled(CircularProgress)<ThrobberStyledProps>(
  ({theme, small}) => ({
    position: 'relative',
    width: small ? '2em' : '3em',
    margin: '1em',
    color: (theme as Theme).palette.secondary.main,
  }),
);

export function Throbber({small}: {small?: boolean}): JSX.Element {
  return (
    <ThrobberContainer>
      <ThrobberStyled small={small} />
    </ThrobberContainer>
  );
}
