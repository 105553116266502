import React from 'react';

import {Link, useLocation} from 'react-router-dom';

import {ThemeProvider, createTheme} from '@mui/material';

import './App.scss';
import logo from './assets/images/logo.svg';
import Footer from './components/footer/Footer';
import useScrollToTop from './hooks/useScrollToTop';
import SmartBanner from './components/smartbanner/SmartBanner';

const theme = createTheme();

const styles = {
  overflowHidden: {
    overflow: 'hidden',
  },
};

const storeTexts = {ios: 'On the App Store', android: 'In Google Play Store'};

function App(props: {children?: React.ReactNode}): JSX.Element {
  useScrollToTop();
  const {pathname} = useLocation();
  const showFooter = !['/join', '/download', '/launch'].includes(pathname);
  const showBanner = !['/join', '/download', '/launch'].includes(pathname);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Link className="App-logo" to="/">
          <img src={logo} alt="slip-logo" />
        </Link>
        {showBanner && (
          <SmartBanner
            title={'Slip.'}
            author="Slip B.V."
            position="bottom"
            storeText={storeTexts}
          />
        )}
        <main
          // avoid a horizontal overflow in LandingPage.tsx
          style={pathname === '/' ? styles.overflowHidden : undefined}>
          {props.children}
        </main>
        {showFooter && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;
