import React, {useCallback, useEffect, useState} from 'react';

import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';

import {API} from '../../classes/API';
import Card from '../../components/card/Card';
import {ErrorMessage} from '../../components/error/ErrorMessage';
import LangSelection from '../../components/langSelection/LangSelection';
import {Throbber} from '../../components/throbber/Throbber';
import {Language, supportedLang} from '../../constants/language.constants';
import useLanguage from '../../hooks/useLanguage';
import {normalizeLanguageTag} from '../../utils/i18n';

type Text = {
  slug: string;
  translations?: Translation[];
};

type Translation = {
  locale: string;
  content: string;
};

type LegalDocument = {
  id: number;
  title: Text;
  currentLegalDocumentVersionId?: number;
  legalDocumentVersions?: LegalDocumentVersion[];
};

type LegalDocumentVersion = {
  id: number;
  text: Text;
  changeText: Text;
};

export function LegalDocumentPage(): JSX.Element {
  const params = useParams<{id: string; versionId: string}>();
  const [legalDocument, setLegalDocument] = useState<LegalDocument | null>();
  const [legalDocumentVersion, setLegalDocumentVersion] =
    useState<LegalDocumentVersion | null>();
  const [error, setError] = useState<Error | undefined>();
  const [lang, setLang] = useLanguage();

  const legalDocumentId = Number(params.id);
  const legalDocumentVersionId = Number(params.versionId);

  const fetch = useCallback(async () => {
    try {
      if (
        (isNaN(legalDocumentId) &&
          !(typeof params.id === 'string' && params.id.length > 3)) ||
        params.id === undefined
      ) {
        console.warn(
          'Invalid params: ' +
            JSON.stringify({params, legalDocumentId, legalDocumentVersionId}),
        );
        throw new Error('Invalid parameters');
      }

      setError(undefined);

      const response = (await API.instance.getLegalDocumentVersion(
        params.id,
        legalDocumentVersionId,
      )) as LegalDocument;
      setLegalDocument(response);

      const versionIdToShow = [undefined, 'current'].includes(params.versionId)
        ? response?.currentLegalDocumentVersionId
        : legalDocumentVersionId;
      const version = response.legalDocumentVersions?.find(
        x => x.id === versionIdToShow,
      );
      if (!version) {
        console.error('Failed to find version', {
          legalDocumentId,
          legalDocumentVersionId,
          versionIdToShow,
        });
        throw new Error(
          `Unable to find requested legal document version #${versionIdToShow}`,
        );
      }
      setLegalDocumentVersion(version);
    } catch (e) {
      setError(e as Error);
    }
  }, [legalDocumentId, params, legalDocumentVersionId]);

  // Re-fetch whenever params change
  useEffect(() => {
    fetch();
  }, [fetch]);

  const content = legalDocumentVersion?.text.translations?.find(
    x => normalizeLanguageTag(x.locale) === lang,
  )?.content;

  const legalDocumentLanguages: string[] = legalDocument?.title.translations
    ?.map(x => normalizeLanguageTag(x.locale))
    .filter(x => x !== undefined) as string[];

  return (
    <section className="container mt-16 xl:mt-20 mx-auto">
      <Card>
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <>
            <LangSelection
              languages={legalDocumentLanguages ?? []}
              selected={lang}
              onSelect={selected => {
                const isInSupportedLang =
                  supportedLang.indexOf(selected as Language) !== -1;
                if (isInSupportedLang) {
                  setLang(selected as Language);
                }
              }}
            />
            {content ? <ReactMarkdown>{content}</ReactMarkdown> : <Throbber />}
          </>
        )}
      </Card>
    </section>
  );
}
