import React from 'react';

import './phone.scss';

interface PhoneProps {
  src: string;
}

function Phone({src}: PhoneProps) {
  return <img className={'Phone w-full'} src={src} alt="phone" />;
}

export default Phone;
