import React, {useLayoutEffect, useState} from 'react';
import {Language} from '../../constants/language.constants';
import {PageType} from '../../download/constants';
import useQuery from '../../hooks/useQuery';
import {API} from '../../classes/API';
import {InviteParameters} from '../../download/invite';
import {LaunchPage} from '../LaunchPage/LaunchPage';

type InvitePageProps = {
  language?: Language;
};

export function InvitePage({language}: InvitePageProps): JSX.Element {
  const query = useQuery();
  const profileId = query.get('inviter');
  const api = API.getInstance();
  const [parameters, setParameters] = useState<InviteParameters | null>(null);

  useLayoutEffect(() => {
    if (profileId) {
      api
        .getProfile(profileId)
        .then(({data: profile, error}) => {
          if (!profile) {
            console.warn('Failed to get profile by id ' + profileId, error);
            return;
          }
          setParameters({
            firstName: profile.firstName,
            avatarUri: api.getPublicURL(profile.avatar?.URI),
          });
        })
        .catch(error => {
          console.error('Failed to get profile by id ' + profileId, error);
        });
    }
  }, [api, profileId]);

  return (
    <LaunchPage
      language={language}
      parameters={parameters}
      type={PageType.DOWNLOAD_INVITE}
      goto="profile"
    />
  );
}
