import React from 'react';

import './button.scss';

interface ButtonProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
}

function Button({className = '', onClick, children}: ButtonProps): JSX.Element {
  return (
    <button className={'sl-button ' + className} onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
