import React, {useCallback, useEffect, useRef} from 'react';

import {isElementInViewport} from '../../utils';
import VideoFrameJenney from './VideoFrameJenney';
import VideoFrameManouk from './VideoFrameManouk';
import './phoneVideo.scss';

interface PhoneVideoProps {
  src: string;
  frame: 'jenney' | 'manouk';
}

export const draw = (
  src: string,
  canvas: HTMLCanvasElement,
): HTMLVideoElement | undefined => {
  if (canvas) {
    // Get the Canvas context
    const ctx = canvas.getContext('2d');

    // Create a video label and set the related properties
    const video: HTMLVideoElement = document.createElement('video');

    video.preload = 'auto';
    video.autoplay = true;
    video.loop = true;
    video.muted = true;
    video.playsInline = true; // Needed for Auto play for iPhone
    video.src = src;
    video.play().catch(e => console.error(e));

    let animationFrame = -1;

    // Go to first frame will trigger seeked
    video.addEventListener('loadedmetadata', () => {
      video.currentTime = 1;
    });

    // Draw at least 1 frame instead of nothing
    video.addEventListener('seeked', () => {
      // Fill vertically
      const vRatio = (canvas.height / video.videoHeight) * video.videoWidth;
      ctx?.drawImage(video, 0, 0, vRatio, canvas.height);
      video.play().catch(e => console.log(e));
    });

    // Monitor Video 's Play Events, once started, draw the video frame by frame to Canvas
    video.addEventListener(
      'play',
      () => {
        const play = () => {
          // Fill vertically
          const vRatio = (canvas.height / video.videoHeight) * video.videoWidth;
          ctx?.drawImage(video, 0, 0, vRatio, canvas.height);
          animationFrame = requestAnimationFrame(play);
        };
        play();
      },
      false,
    );

    // Monitor Video 's Pause Events, once paused, cancel the animationFrame
    video.addEventListener(
      'pause',
      () => {
        if (animationFrame !== -1) cancelAnimationFrame(animationFrame);
      },
      false,
    );

    return video;
  }
};

function PhoneVideo({src, frame}: PhoneVideoProps): JSX.Element {
  const canvas = useRef<HTMLCanvasElement>(null);
  const video = useRef<HTMLVideoElement>();

  const handleScroll = useCallback(async () => {
    if (canvas.current && isElementInViewport(canvas.current)) {
      if (video.current?.paused) {
        try {
          await video.current.play();
        } catch (e) {
          console.log('Oops');
        }
      }
    } else if (!video.current?.paused) {
      try {
        await video.current?.pause();
      } catch (e) {
        console.log('Oops');
      }
    }
  }, [video]);

  useEffect(() => {
    if (canvas.current) {
      const value = draw(src, canvas.current);
      if (value) {
        video.current = value;
      }
    }

    window.addEventListener('DOMContentLoaded', handleScroll);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('DOMContentLoaded', handleScroll);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [canvas, handleScroll, src]);

  return (
    <div className="PhoneVideo">
      <canvas className="video-canvas" ref={canvas} width={375} height={812} />
      {frame === 'jenney' ? (
        <VideoFrameJenney className="phone-frame" />
      ) : (
        <VideoFrameManouk className="phone-frame" />
      )}
    </div>
  );
}

export default PhoneVideo;
