import {DownloadPageData} from '../types';

export const downloadPageData_Beta: DownloadPageData = {
  text: {
    en: '# Join ![titleLogo](/logo.svg)\n ## Download the app and start showing off your skills 🤩  \nJoin us in our beta-test phase! Help us develop the app by sharing your personal development. Be part of the movement and join us today!',
    nl: '# Welkom bij ![titleLogo](/logo.svg)\n ## Download de app en toon je skills 🤩  \n Join onze beta-testfase! Help ons de app te ontwikkelen door je eigen ontwikkeling te delen. Maak deel uit van de beweging en sluit je vandaag nog bij ons aan!',
  },
  platforms: {
    iOS: {
      url: 'https://testflight.apple.com/join/AQ9flvlB',
    },
    Android: {
      url: 'https://play.google.com/store/apps/details?id=com.slipapp&utm_source=website&utm_campaign=beta_download&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    },
  },
};
